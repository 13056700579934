<template>
  <div class="crm f-flex">
    <div class="crm-name">投註明細</div>
  </div>
  <div class="crm f-flex" >
    <div class="crm-tab f-flex" style="border:1px solid #ccc; color:#000; border-radius: 30px;">
      <div
        :class="{ 'crm-tab-li': true, 'crm-cur': query.dto.termNumber == '1' }"
        @click="tabChangeEvent('1')"
        style="color:#666"
      >
        本期
      </div>
      <div
        :class="{ 'crm-tab-li': true, 'crm-cur': query.dto.termNumber == '0' }"
        style="color:#666"
        @click="tabChangeEvent('0')"
      >
        全部
      </div>
    </div>
    <div class="f-plr10">
      <select class="ant-select" @change="selectChangeEvent">
        <option
          :value="item.value"
          v-for="(item, index) in initial.types"
          :key="index"
        >
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
  <div v-if="loading" class="loading"></div>
  <div class="table-con">
    <table class="m-table">
      <tr>
        <th style="width: 200px; background:#fff !important; color:#000; font-weight: bold;">時间</th>
        <th style="width: 130px; background:#fff !important; color:#000; font-weight: bold;">投註类别</th>
        <th style="width: 260px; background:#fff !important; color:#000; font-weight: bold;">號碼</th>
        <th style="width: 80px; background:#fff !important; color:#000; font-weight: bold;">賠率</th>
        <th style="width: 140px; background:#fff !important; color:#000; font-weight: bold;">投註金額</th>
        <th style="width: 140px; background:#fff !important; color:#000; font-weight: bold;">盈虧</th>
        <th style="width: 140px; background:#fff !important; color:#000; font-weight: bold;"  >退水</th>
        <th style="width: 140px; background:#fff !important; color:#000; font-weight: bold;">總盈虧</th>
      </tr>

      <tr v-for="(item, index) in records.data" :key="index"  :style="item.betContent.indexOf('该单作废') >= 0?'background-color:red':'' ">
        <td style="width: 200px">
          {{ formatTime(item.gmtCreate, "YYYY-MM-DD HH:mm:ss") }}
        </td>
        <td style="width: 130px">{{ item.betTypeName }}</td>
        <td style="width: 260px">{{ item.betContent }}</td>
        <td
          style="width: 80px"
          v-if="item.betContent.indexOf(',') > 0 && currentGame.code < 20000"
        >
          <a
            href="javascript:void(0)"
            @click="showOddsDetail(item)"
            style="color: blue"
            >内详</a
          >
        </td>
        <td style="width: 140px" v-else>
          {{ item.currentOdds }}
        </td>
        <td style="width: 140px">{{ item.betMoney }}</td>
        <td style="width: 140px">{{ item.winMoney }}</td>
        <td style="width: 140px" >
          {{ mathFloor(item.returnRate * item.betMoney, 100) }}
        </td>
        <td style="">
          {{
            item.status == 0
              ? 0
              : mathFloor(
                  item.winMoney +
                    item.betMoney * item.returnRate -
                    item.betMoney,
                  100
                )
          }}
        </td>
      </tr>

      <tr v-if="records.total > 0" style="font-weight: bold">
        <td style="width: 200px">
          <span style="font-weight: bold">合计</span>
        </td>
        <td style="width: 130px"></td>
        <td style="width: 260px"></td>
        <td style="width: 80px"></td>
        <td style="width: 140px">{{ records.summary.betMoney }}</td>
        <td style="width: 140px">{{ records.summary.winMoney }}</td>
        <td style="width: 140px" >{{ records.summary.returnMoney }}</td>
        <td style="width: 140px">
          {{
            mathFloor(
              records.summary.winMoney + records.summary.returnMoney,
              100
            )
          }}
        </td>
      </tr>
      <tr v-else>
        <td style="width: 100%" colspan="8">暫無數據</td>
      </tr>
    </table>

    <XtxPagination
      v-if="records.total > 0"
      :current-page="query.pageNo"
      :page-size="query.pageSize"
      :total="records.total"
      @current-change="pageChangeEvent"
    />
    <!--復式投註明細-->
    <LhcBetOddsDetailConfirm
      ref="oddsDetail"
      :title="records.title"
    ></LhcBetOddsDetailConfirm>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  reactive,
  nextTick,
  computed,
  watch,
  getCurrentInstance,
} from "vue";
import LhcBetOddsDetailConfirm from "@/views/liuhecai/components/lhc-bet-odds-detail-confirm.vue";
import { useStore } from "vuex";
import moment from "moment";
export default {
  name: "LhcBetPage",
  components: { LhcBetOddsDetailConfirm },
  setup() {
    const store = useStore();
    const currentGame = computed(() => {
      return store.state.app.currentGame;
    });

    watch(
      () => [currentGame.value.code],
      () => {
        query.dto.gameCode = currentGame.value.code;
        requestBetPage();
        setTimeout(() => {
          requestSummary();
        }, 500);
      }
    );
    // 加载中
    const loading = ref(false);

    const oddsDetail = ref(null);

    let query = reactive({
      pageNo: 1,
      pageSize: 20,
      dto: {
        termNumber: "1",
        betType: 9999,
        gameCode: currentGame.value.code,
      },
    });
    let records = reactive({
      data: [],
      total: 0,
      summary: {
        betMoney: 0,
        returnMoney: 0,
        winMoney: 0,
      },
      title: "註單明细",
    });

    let initial = reactive({
      types: [],
    });
    onMounted(() => {
      nextTick(() => {
        requestBetTypeEvent();
      });
    });
    //获取投註类型
    const requestBetTypeEvent = () => {
      store
        .dispatch("game/requestBetTypeAction", {
          gameCode: currentGame.value.code,
        })
        .then((res) => {
          if (res && res.code == 0) {
            if (res.res.length > 0) {
              res.res.unshift({
                label: "全部",
                name: "全部",
                value: "9999",
              });
            }
            initial.types = res.res;
          } else {
            initial.types = [];
          }
        })
        .then(() => {
          setTimeout(() => {
            requestBetPage();
          }, 500);
        })
        .then(() => {
          setTimeout(() => {
            requestSummary();
          }, 500);
        });
    };
    //获取投註明細
    const requestBetPage = () => {
      query.dto.gameCode = currentGame.value.code;
      loading.value = true;
      store
        .dispatch("game/requestBetPageAction", query)
        .then((res) => {
          loading.value = false;
          if (res && res.code == 0) {
            records.data = res.res.rows;
            records.total = res.res.total;
          } else {
            records.data = [];
            records.total = 0;
          }
        })
        .catch(() => {
          loading.value = false;
        });
    };
    /**
     *
     */
    const requestSummary = () => {
      store
        .dispatch("game/requestBetContentSummaryAction", {
          betType: query.dto.betType,
          termNumber: query.dto.termNumber,
          gameCode: currentGame.value.code,
        })
        .then((res) => {
          if (res.code == 0 && res.res) {
            Object.assign(records.summary, res.res);
          }
        });
    };
    const pageChangeEvent = ($event) => {
      query.pageNo = $event;
      requestBetPage();
    };
    const tabChangeEvent = (type) => {
      query.dto.termNumber = type;
      query.pageNo = 1;
      requestBetPage();
      setTimeout(() => {
        requestSummary();
      }, 500);
    };
    const selectChangeEvent = ($event) => {
      query.dto.betType = $event.target.value;
      query.pageNo = 1;
      requestBetPage();
      setTimeout(() => {
        requestSummary();
      }, 500);
    };
    /**
     * 显示明细
     */
    const showOddsDetail = (item) => {
      store
        .dispatch("game/requestBetOddsDetailAction", { bId: item.id })
        .then((res) => {
          if (res.code == 0) {
            oddsDetail.value.open({
              betContent: item.betContent,
              currentOdds: item.currentOdds,
              betTypeName: item.betTypeName,
              amount: res.res[0].betMoney,
              odds: res.res,
            });
          }
        });
    };
    //格式化時间
    const formatTime = (time, format) => {
      return getCurrentInstance().appContext.config.globalProperties.$moment(time)
        .format(format);
    };
    const mathFloor = (num, multiple) => {
      return Math.floor(num * multiple) / multiple;
    };
    return {
      oddsDetail,
      loading,
      initial,
      records,
      query,
      formatTime,
      pageChangeEvent,
      tabChangeEvent,
      selectChangeEvent,
      mathFloor,
      requestSummary,
      showOddsDetail,
      currentGame,
    };
  },
};
</script>