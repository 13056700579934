<template>
  <XtxDialog
    :title="title"
    :visible="visible"
    @closeDialog="
      () => {
        visible = false;
      }
    "
  >
    <div v-if="loading" class="loading"></div>

    <div class="settle-t">
      <div class="f-flex">
        <div class="g-w60">
          <span class="settle-t-label">{{ result.betTypeName }}</span>
          {{ result.betContent }}
          <span class="f-f-red"
            >+ {{ result.odds[0] ? result.odds[0].betMoney : 1 }}</span
          >
        </div>
        <div class="g-w40" style="text-align: right">
          (組合數：<span class="f-f-red">{{ result.odds.length }}</span
          >,金額：<span class="f-f-red">{{
            mathFloor(result.odds.length * result.amount, 100)
          }}</span
          >)
        </div>
      </div>
      <!-- <div class="f-flex">
        <div class="g-w60">
          <span class="settle-t-label">賠率：</span>
          {{ result.currentOdds }}
        </div>
      </div> -->
    </div>

    <div class="settle-con">
      <div class="f-flex f-flex-wrap">
        <div
          class="g-w25"
          v-for="(item, index) in result.odds"
          :key="index"
          :class="{ 'bg-yl': item.winMoney > 0 }"
        >
          <span class="f-f-blue">{{ item.betContent }}</span
          >(<span style="color: red">{{ item.currentOdds }}</span
          >)
        </div>
      </div>
    </div>
  </XtxDialog>
</template>

<script>
import { reactive, ref, nextTick } from "vue";
export default {
  name: "LhcBetOddsDetail",
  props: {
    title: {
      type: String,
      default: "註單確認",
    },
  },
  setup(props, { emit }) {
    let loading = ref(false);
    /**
     *是否显示弹窗
     */
    let visible = ref(false);
    let result = ref({
      odds: [],
      betTypeName: "",
      amount: 0,
      currentOdds: "",
    });
    //打开弹窗钩子
    const open = (data) => {
      visible.value = true;
      result.value = data;
    };
    const mathFloor = (num, multiple) => {
      return Math.floor(num * multiple) / multiple;
    };
    return {
      loading,
      visible,
      open,
      result,
      mathFloor,
    };
  },
};
</script>

<style scoped lang="less">
.xtx-dialog {
  :deep(.wrapper) {
    width: 100%;
    min-width: 1000px;
    .body {
      font-size: 12px;
      padding: 5px 5px;
    }
  }
}
.bg-yl {
  background: #ffff00;
}
.settle-t {
  padding: 10px;
  line-height: 30px;
  font-size: 16px;
  background: #fefdf1;
  border: 1px solid #eec561;
  border-left: 0;
  border-right: 0;
}
.settle-t-label {
  display: inline-block;
  width: 125px;
  color: #000;
}
.settle-con {
  //   background: #ffff00;
  color: #333;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  border: 20px solid #5c90d0;
  border-left: 0;
  border-right: 0;
  max-height: 50vh;
  overflow-y: auto;
}
.settle-con .g-w25 {
  border: 1px solid #3e6ba3;
  box-sizing: border-box;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
